@import '@ping/assets/scss/theme/variables';

.ping-toast {
  font-family: inherit;
  width: 100%;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background: $mirage-light !important;
  color: $white !important;

  &__content {
    font-family: inherit;
    font-size: 12px;
  }

  :global {
    .Toastify {
      &__toast-body {
        align-items: flex-start;
      }

      &__toast-icon {
        width: 1rem;
        margin-top: 1px;
      }

      &__close-button {
        align-self: center;
        margin-right: 10px;

        svg {
          display: block;
        }
      }
    }
  }

  &--success {
    :global {
      .Toastify {
        &__toast-icon {
          color: $green;
        }

        &__progress-bar {
          background: $green !important;
        }
      }
    }
  }

  &--warning {
    :global {
      .Toastify {
        &__toast-icon {
          color: $yellow;
        }

        &__progress-bar {
          background: $yellow !important;
        }
      }
    }
  }

  &--error {
    :global {
      .Toastify {
        &__toast-icon {
          color: $red;
        }

        &__progress-bar {
          background: $red !important;
        }
      }
    }
  }
}
