@import '@ping/assets/scss/theme/variables';


.language-dialog{
  &__wrapper{
    color: $white;
    h5{
      margin-bottom: 32px;
    }
  }
  &__content{
    color: $white;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  &__item{
    margin-bottom: 32px;
    color: $gray;
    cursor: pointer;
    &:hover{
      color: $white;
    }
  }
}
