@import './_variables';

.dark,
.dark header {
  background: $mirage;
}
.dark .light-bb,
.dark .input-group-text {
  border: none;
}
.dark header nav a {
  color: $gray;
}
.dark header nav a:hover {
  color: $white;
}
.dark .nav-link-active {
  color: $white;
}
.dark header nav a.btn:hover {
  color: $white;
}
.dark header nav .nav-item.active a {
  color: $white;
}
.dark .market-pairs {
  border: 1px solid $charade;
  background: $mirage;
}
.dark .order-book,
.dark .market-history,
.dark .market-news {
  border: 1px solid $charade;
}

.dark .markets-pair-list .nav-link {
  color: $trout;
}
.dark .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $nevada;
}
.dark .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: $nevada;
}
.dark .form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: $nevada;
}
.dark .form-control:-moz-placeholder {
  /* Firefox 18- */
  color: $nevada;
}
.dark .market-pairs .nav-link.active,
.dark #STAR i {
  color: $white;
}
.dark .green {
  color: $green;
}
.dark .red {
  color: $red;
}
.dark .market-pairs .nav {
  background: $mirage-lighter;
}
.dark .market-pairs input {
  border-color: $charade;
  background: $charade;
}
.dark .market-pairs input {
  color: $white;
}
.dark .heading {
  background: $mirage-lighter;
  color: $white;
}
.dark .ob-heading span {
  color: $trout;
}
.dark .market-history .nav {
  background: $mirage-lighter;
}

.dark .red-bg-80:after,
.dark .red-bg-60:after,
.dark .red-bg-40:after,
.dark .red-bg-20:after,
.dark .red-bg-10:after,
.dark .red-bg-8:after,
.dark .red-bg-5:after,
.dark .red-bg:after {
  background: #ff231f63;
}
.dark .market-news {
  background: $mirage;
}
.dark .market-news li {
  border-bottom: 1px solid $charade;
}
.dark .market-news li:hover {
  background: $charade;
}
.dark .market-news li strong,
.dark .market-news li a {
  color: $loblolly;
}

.dark {
  .nav-tabs {
    .nav-link {
      border: transparent;
      &:hover {
        border: transparent;
      }
      &.active {
        background: transparent;
        border: transparent;
      }
    }
  }
}
.dark .market-order {
  .nav {
    background: $mirage-lighter;
  }
  .nav-tabs {
    border-bottom: 0;
  }
  .nav-link {
    color: $gray;
  }
  .nav-link.active {
    background-color: transparent;
    color: $white;
  }
}
.dark .market-trade {
  border: 1px solid $charade;
  background: $mirage;
}

.dark .market-trade-overlay {
  .market-trade-overlay__title {
    color: $white;
  }
  .market-trade-overlay__description {
    color: $periwinkle-gray;
  }
  background: linear-gradient(180deg, rgba(19, 23, 34, 0.95) 14.1%, rgba(19, 23, 34, 0.7) 100%);
}

.dark .market-trade .nav .nav-item .nav-link.active {
  background: transparent;
  color: $white;
}
.dark .market-trade .nav .nav-item .nav-link {
  color: $trout;
}
.dark .market-trade p {
  color: $loblolly;
}
.dark .market-trade-list li a {
  background: $charade;
  color: $white;
}
.dark .market-trade-buy .market-trade-list li a:hover {
  background: $green;
  color: $white;
}
.dark .market-trade-sell .market-trade-list li a:hover {
  background: $torch-red;
  color: $white;
}
.dark #darkDepthChart {
  background: $mirage;
}
.dark .markets {
  background: $mirage;
}

.dark .markets-pair-list .nav {
  background: $mirage-lighter;
}
.dark .markets-pair-list .nav-link.active,
.dark #STAR i {
  color: $white;
}
.dark .markets-pair-list .load-more {
  border: 1px solid $charade;
  color: $white;
}
.dark .markets-pair-list .load-more:hover {
  color: $white;
  border: 1px solid $blue;
}
.dark .markets-content h2,
.dark .markets-content p {
  color: $white;
}
.dark .markets-content span.green,
.dark .markets-content span.red {
  color: $white;
}
.dark .markets-container {
  background: $ebony-clay;
}

.dark header nav .nav-item.active a.btn:hover {
  color: $white;
}

.dark .custom-control-input:checked ~ .custom-control-label::before {
  color: $white;
  border-color: $blue;
  background-color: $blue;
}

.dark .dropdown-menu p {
  color: $white;
}

.dark .dropdown-header {
  border-bottom: 1px solid $charade;
}
.dark .dropdown-footer {
  border-top: 1px solid $charade;
}

.dark .header-img-icon .profile-nav .nav-item .nav-link:hover {
  background-color: $charade;
  color: $white;
}

.dark .form-control,
.dark .settings-profile select {
  box-sizing: border-box;
  background-color: $dropdown-bg !important;
  // border: 1px solid #3e445c !important;
  height: 3rem;
  border: unset;
  border-radius: 0.125rem;
  color: $white;

  &:focus {
    color: $white;
    outline: none;
    box-shadow: none;
  }
}

.dark .has-input-error {
  border: 1px solid $red !important;
}

.dark .form-access form {
  padding: 30px;
  border-radius: 5px;
  min-width: 350px;
  box-shadow: 0 0 10px 0 $mirage;
  border: 1px solid $charade;
}
.dark .form-access h2,
.dark .form-access {
  color: $white;
}

.dark .form-access .custom-control-label::before {
  border: $charade solid 1px;
  background-color: $charade;
}

.dark .wallet .nav-pills a,
.dark .wallet-history {
  border-top: 1px solid $charade;
}
.dark .wallet .nav {
  background: $ebony-clay;
}
.dark .wallet h2,
.dark .wallet h3,
.dark .wallet h4,
.dark .wallet p {
  color: $white !important;
}
.dark .wallet button.green,
.dark .wallet button.red,
.dark .wallet .nav-pills a.active h2,
.dark .wallet .nav-pills a.active h3,
.dark .wallet .nav-pills a.active p {
  color: $white;
}

.dark .wallet .tab-content li:first-child {
  border-bottom: 1px solid $charade;
}
.dark .wallet-history tr {
  background: $ebony-clay;
}
.dark .wallet-address input,
.dark .wallet-address input:focus {
  border: 1px solid $charade;
  background: $charade;
  box-shadow: none;
  color: $white;
}
.dark .depth-chart-inner {
  border: 1px solid $charade;
}

.dark .markets-capital-item {
  box-shadow: 0 0 10px 0 $mirage;
  border: 1px solid $charade;
}

.dark .markets-capital-item h2,
.dark .markets-capital-item h4 {
  color: $white;
}

.dark .settings-nav {
  background: $ebony-clay;
}

.dark .settings-nav .nav-link {
  color: $white;
}

.dark .settings-notification li {
  border-bottom: 1px solid $charade;
}
.dark .settings-notification li:last-child {
  border-bottom: 0;
}
.dark .settings-notification p {
  color: $white;
}
.dark .settings-notification span {
  color: $trout;
}

.dark .settings label {
  color: $white;
}
.dark .settings .custom-file-label {
  background: $charade;
  border: 1px solid $charade;
  color: $nevada;
}

.dark .settings-profile input[type='submit'] {
  border: 1px solid $blue;
}

.dark .sidebar {
  &-link {
    color: $white;
    @include svgColor($white);
  }
}

.dark .market-carousel-item {
  border: 1px solid $charade;
}
.dark .market-carousel-item {
  color: $white;
}
.dark .market-carousel-item .btn.buy:hover,
.dark .market-carousel-item .btn.sell:hover {
  color: $white;
  border-color: $white;
}
.dark .news-details,
.dark .page-content {
  color: $white;
}
.dark .navbar-toggler-icon {
  filter: contrast(0.1);
}
