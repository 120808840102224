@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

@keyframes modal-scale {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  60% {
    transform: scale(1.1);
    opacity: 0.6;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes modal-out {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.popup {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 900;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gray;
  border-radius: 3px;

  &.fullScreen {
    border-radius: 0;
    inset: 0;
    transform: translate(0, 0);

    .popup__content {
      padding: 0;
      background-color: $mirage;
      inline-size: 100vw;
      block-size: 100vh;
      max-block-size: 100%;

      @include media-breakpoint-up('md') {
        padding: 2rem;
      }
    }
  }

  &__overlay {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(19, 23, 34, 0.7);
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    align-items: center;

    &.center {
      justify-content: start;

      .popup__left-part {
        justify-content: center;
        width: 100%;
      }
    }

    svg {
      cursor: pointer;
    }
  }

  &__cross {
    width: 12px;
    height: 12px;
    cursor: pointer;
    z-index: 1;
  }

  &__content {
    animation: modal-scale 0.2s linear;
    background: $mirage-light;
    border-radius: 3px;
    padding: 32px;
    width: 576px;
    height: auto;
    // TODO: temporarily handle overflow until design will be completed
    // CHECK: @ping/containers/admin/orders/OrdersFilters/style.module.scss
    max-height: calc(75vh + 2 * 38px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    @media (max-width: 767px) {
      max-width: 100%;
      width: 396px;
      padding: 24px;
    }

    &_with-scroll {
      overflow-y: auto;
      max-height: 100vh;
    }

    &_closing {
      animation: modal-out 0.1s linear;
      opacity: 0;
    }
  }

  &__left-part {
    display: flex;
  }

  &__refining-text {
    margin-left: 0.71rem;
    font-weight: bold;

    color: $light-grey;
  }

  .withdraw_input {
    width: 300px;
  }
}
