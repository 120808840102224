@import '@ping/assets/scss/theme/variables';

.formField {
  select {
    width: 100% !important;
  }
  &__label {
    width: 100%;
    color: $cadet-blue;
    font-size: 1rem;
  }
}
