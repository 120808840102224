@import '@ping/assets/scss/theme/variables';

.component_btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: 2px solid transparent;
  background-color: transparent;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: $white;
  a {
    color: inherit;
    text-decoration: none;
  }

  &.design {
    &_general {
      &.type {
        &_primary {
          background: $blue;
          border-color: $blue;
          &:hover {
            background-color: $btn-blue-hover;
            border-color: $btn-blue-hover;
          }

          &.disabled {
            opacity: 0.38;
            cursor: not-allowed;
          }
        }

        &_secondary {
          border-color: $blue;
          &:hover {
            border-color: $btn-blue-hover;
          }

          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }

    &_sell {
      &.type {
        &_primary {
          background: $red;
          border-color: $red;
          &:hover {
            background-color: $btn-red-hover;
            border-color: $btn-red-hover;
          }

          &.disabled {
            opacity: 0.38;
            cursor: not-allowed;
          }
        }

        &_secondary {
          border-color: $red;
          &:hover {
            border-color: $btn-red-hover;
          }
          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }

    &_buy {
      &.type {
        &_primary {
          background: $green;
          border-color: $green;
          &:hover {
            background-color: $btn-green-hover;
            border-color: $btn-green-hover;
          }

          &.disabled {
            opacity: 0.38;
            cursor: not-allowed;
          }
        }

        &_secondary {
          border-color: $green;
          &:hover {
            border-color: $btn-green-hover;
          }
          &.disable {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }

    &_gray {
      &.type {
        &_primary {
          background: $deep-blue;
          border-color: $deep-blue;
          &:hover {
            background-color: $deep-blue;
            border-color: $deep-blue;
          }

          &.disabled {
            opacity: 0.38;
            cursor: not-allowed;
          }
        }

        &_secondary {
          border-color: $deep-blue;
          &:hover {
            border-color: $deep-blue;
          }
          &.disable {
            opacity: 0.5;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  &.size {
    &_large {
      padding: 12px 16px;
      width: 316px;
      height: 48px;
    }
    &_small {
      padding: 8px;
      width: 192px;
      height: 40px;
      font-size: 14px;
      line-height: 18px;
    }
    &_extra-large {
      padding: 12px 16px;
      width: 100%;
      height: 48px;
    }
  }

  &.uppercase {
    text-transform: uppercase;
  }
}
