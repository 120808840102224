@import '@ping/assets/scss/theme/variables';

/* Chrome, Edge, and Safari */
::-webkit-scrollbar {
  width: 6px !important; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: $dropdown-bg !important; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  border-radius: 2px !important; /* roundness of the scroll thumb */
  border: 4px solid $border-color !important; /* creates padding around scroll thumb */
}

/* Firefox */
* {
  scrollbar-width: thin !important;
  scrollbar-color: $border-color $dropdown-bg !important;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}
.mtb15 {
  margin: 15px 0;
}
.mt15 {
  margin-top: 15px;
}
.mb15 {
  margin-bottom: 15px;
}
.ml15 {
  margin-left: 15px;
}
.mr15 {
  margin-right: 15px;
}
.ptb70 {
  padding: 70px 0;
}
.pb70 {
  padding-bottom: 70px;
}
.pt70 {
  padding-top: 70px;
}
.pb40 {
  padding-bottom: 40px;
}
::-webkit-scrollbar {
  background: transparent;
  width: 3px;
  height: 6px;
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.07);
  border: solid rgba(88, 88, 88, 0.12) 2px;
  border-radius: 5px;
}
.dropdown-toggle::after {
  vertical-align: 1px;
}
.input-group-text,
.form-control {
  border-radius: 5px;
}
.nav-lin {
  color: $gray;
}
.heading {
  background: #f5f9fc;
  font-size: 14px;
  font-weight: 400;
  padding: 13px;
  margin: 0;
  color: $gray;
}
.center-column {
  display: block;
  text-align: center;
}
.green {
  color: $green;
}
.red {
  color: $red;
}
.light-bb,
.input-group-text {
  border: 1px solid $milk-white;
}
.dark-bb {
  border-bottom: 1px solid $charade;
}
#darkDepthChart,
#lightDepthChart {
  width: 100%;
  height: 349px;
}
.sm-gutters {
  padding-left: 7.5px;
  padding-right: 7.5px;
}
.sm-gutters > .col,
.sm-gutters > [class*='col-'] {
  padding-right: 7.5px;
  padding-left: 7.5px;
}

.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: $dark-blue-opacity;
}
.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: $dark-blue-opacity;
}
.form-control:-ms-input-placeholder {
  /* IE 10+ */
  color: $dark-blue-opacity;
}
.form-control:-moz-placeholder {
  /* Firefox 18- */
  color: $dark-blue-opacity;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}
.nav-tabs .nav-link {
  color: $gray;
  font-size: 16px;
  transition: all 200ms ease;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-link:hover {
  border-color: transparent;
  color: white;
  background-color: transparent;
}
.slick-slide > div {
  margin: 0 8px;
}
.slick-list {
  margin: 0 -8px;
}
.page-content {
  margin: 40px 0 100px;
}

.page-content h2 {
  font-size: 26px;
  margin-bottom: 20px;
  margin-top: 60px;
}

.page-content p {
  font-size: 16px;
}
.news-details {
  padding: 100px 0;
}

.news-details h2 {
  font-size: 26px;
  margin-bottom: 20px;
}

.news-details p {
  font-size: 16px;
}

.has-input-error {
  border: 1px solid $red !important;
}

.input-error {
  color: $red;
  font-size: 14px;
  line-height: initial;
  display: block;
  margin-top: 8px;
}

/* --------------------------- */
/* --------- TOOLTIP --------- */
/* --------------------------- */

.tooltip > .tooltip-inner {
  background-color: $dropdown-bg;
  color: white;
  border: 1px solid $dropdown-bg;
  max-width: 100%;
}

.tooltip {
  &#tooltip-top {
    .arrow:before {
      border-top-color: $dropdown-bg;
    }
    inset: auto auto 6px 0px !important;
  }
  &#tooltip-right {
    .arrow:before {
      border-right-color: $dropdown-bg;
    }
    inset: 0px auto auto 6px !important;
  }
  &#tooltip-left {
    .arrow:before {
      border-left-color: $dropdown-bg;
    }
    inset: 0px 6px auto auto !important;
  }
  &#tooltip-bottom {
    .arrow:before {
      border-bottom-color: $dropdown-bg;
    }
    inset: 6px auto auto 0px !important;
  }
}

.d-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.center {
  display: grid;
  place-content: center;
  place-items: center;
}

.cursor-pointer {
  cursor: pointer;
}

/* --------------------------- */
/* ---------- STATUS --------- */
/* --------------------------- */

.status {
  &--green {
    color: $green;
  }
  &--red {
    color: $red;
  }
  &--blue {
    color: $blue;
  }
  &--yellow {
    color: $yellow;
  }
  &--gray {
    color: $gray;
  }
}
