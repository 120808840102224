@import '@ping/assets/scss/theme/variables';

.market-news {
  border: 1px solid $milk-white;
  border-radius: 2px;
}

.market-news li a {
  color: $gray;
  font-size: 11px;
  display: block;
  text-decoration: none;
}
.market-news li:hover {
  background: $porcelain;
}
.market-news li strong {
  display: block;
  font-size: 13px;
  margin-bottom: 2px;
  color: #4a4a4ac7;
}

.market-news li span {
  display: block;
  margin-top: 5px;
}

.market-news li {
  border-bottom: 1px solid $white-lilac;
  padding: 10px 13px;
}

.market-news li:last-child {
  border: 0;
}

.market-news ul {
  height: 348px;
  overflow-y: auto;
}
