@import '@ping/assets/scss/theme/variables';

.alert {
  background: $semi-dark-blue;
  padding: 16px;
  display: flex;
  .icon-wrapper {
    margin-right: 12px;
    svg {
      width: 24px;
      height: 24px;
    }
  }
  &-title {
    display: flex;
    color: $red;
    margin-bottom: 8px;
    line-height: 1;
  }
}
