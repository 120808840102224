@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

@keyframes loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0.2;
  }
}

.user_verification_overlay {
  --overlay-first-bg: rgb(19 23 34 / 0.8);
  --overlay-second-bg: rgba(19 23 34 / 1);
  --overlay-position: fixed;
  --overlay-z-index: 100;

  display: grid;
  place-items: center;

  .user_verification_text {
    color: $white;
    padding: 1rem;
    margin: 0;
    font-size: 1rem;
    text-transform: uppercase;
    animation: loading 1s linear infinite alternate both;

    @include media-breakpoint-up('md') {
      font-size: 1.5rem;
    }
    @include media-breakpoint-up('xl') {
      font-size: 2.5rem;
    }
  }
}
