@import '@ping/assets/scss/theme/variables';
@import '@ping/assets/scss/breakpoint';

.nav-item-icon {
  margin-right: 8px;
  width: 20px;
}

.nav-user {
  align-items: center;
  .nav-language{
    color: $white;
    cursor: pointer;
    padding-right: 12px;
    border-right: 1px solid $border-color;
  }
  #nav-dropdown-currency {
    & ~ :global.dropdown-menu {
      width: 70px;
      min-width: unset;
      left: -15px;
    }
  }
}

.nav-item {
  padding: 0;
  &:hover {
    background: unset;
  }
}

.nav-currency-dropdown {
  display: flex;
  justify-content: center;
  color: $white !important;
  font-size: 16px;
  padding: 5px;
  &:hover {
    cursor: pointer;
    background-color: $dropdown-item-hover-bg;
  }
}

.is-mobile-only {
  @include media-breakpoint-up('md') {
    display: none !important;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

.header {
  :global .nav-link {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
  :global .dropdown-menu {
    background: $dropdown-bg;
    box-shadow: 0 0 10px 0 $mirage;
    border: 1px solid $charade;
    margin-top: 8px !important;
    animation-duration: 0.3s;
    animation-fill-mode: both;
    animation-name: slideIn;
  }
  :global .dropdown-menu::before {
    position: absolute;
    content: '';
    width: 13px;
    height: 13px;
    transform: rotate(45deg);
    background: $dropdown-bg;
    border-top: 1px solid $charade;
    border-left: 1px solid $charade;
    z-index: -1;
  }
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%;
  background-color: $white;

  :global .navbar {
    background-color: $mirage;
    border-bottom: 1px solid $charade;
  }

  &__navbar {
    flex-direction: row !important;
  }

  &__links-nav {
    flex-grow: 1;
    justify-content: center;
    @media (max-width: map-get($breakpoints, 'md')) {
      display: none;
    }
  }
  height: 66px;
}

.header__link {
  align-self: center;
  margin: 0.5rem 14px;
  text-decoration: none !important;
  font-size: 16px;
  cursor: pointer;

  /* For disabling text selection */
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.header__dropdown-item {
  display: flex;
  align-items: center;
  width: 100%;
  clear: both;
  font-weight: 400;
  color: $white !important;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  padding: 8px 16px;
  margin: 4px 0;

  &:hover,
  &:focus,
  &.is-active {
    background-color: $dropdown-item-hover-bg;
    color: $white;
    text-decoration: none;
  }
}

.header__profile-image {
  width: 32px;
}

.header__get-started-button {
  width: fit-content;
}

.logo {
  width: 86px;
  height: 36px;
}

#nav-dropdown-profile:after {
  content: none;
}

#nav-dropdown-profile {
  ~ :global.dropdown-menu {
    position: absolute;
    width: 190px;
    left: -135px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  ~ :global.dropdown-menu::before {
    top: -8px;
    right: 25px;
    padding: 0;
  }
  ~ :global.dropdown-menu .dropdown-divider {
    border-top: 1px solid $border-color;
  }
}

#nav-dropdown-currency {
  @extend #nav-dropdown-profile;
  color: $white;
  @media (max-width: map-get($breakpoints, 'md')) {
    display: none;
  }
}
