@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

.banner {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 11px 16px;
  background: $deep-blue;
  border-bottom: 1.5px solid $red;
  cursor: pointer;
  svg {
    margin-right: 12px;
  }
}
