$red: #f93a59;
$green: #26de81;
$yellow: #f9cf3a;
$blue: #3749e7;
$cerulean-blue: #1066df;
$light-grey-blue: #1066df;
$gray: #6b728f;
$dark-red: #f93a59ad;
$dark-blue: #18214d;
$semi-dark-blue: #1d2130;
$deep-blue: #24293a;
$light-grey: #758696;
$lighter-grey: #7c7c7c;
$milk-white: #e0e3eb;
$white: #fff;
$electric-blue: #5a6282;
$cadet-blue: #b0b6d0;
$charade: #2a2e39;
$dark-blue-opacity: #18214d59;
$sunset-orange: #f74745;
$athens-gray: #e9ecef;
$btn-blue-hover: #2132c6;
$btn-red-hover: #e33350;
$btn-green-hover: #1ac66f;
$catskill-white: #f2f4f9;
$mischka: #cbd2df;
$porcelain: #f6f8f9;
$white-lilac: #f0f3fa;
$torch-red: #ff231f;
$burnt-sienna: #ef5350;
$orange: #ffa500;
$periwinkle-gray: #b8c1e6;
$jungle-green: #26a69a;
$aqua-haze: #eff2f6;
$black-dqueeze: #f5f9fc;
$black: #000;
$mirage: #131722;
$mirage-dark: #141823;
$mirage-light: #181c2a;
$mirage-lighter: #1c2030;
$logan: #b1b6d0;
$gun-powder: #3f445f;
$loblolly: #c5cbce;
$trout: #4f5966;
$nevada: #69727a;
$ebony-clay: #1e222d;
$alto: #d2d2d2;
$color-charcoal-gray: #30364d;
$color-iron: #3e445c;

$dropdown-bg: $deep-blue;
$dropdown-item-hover-bg: $color-iron;
$border-color: $color-charcoal-gray;
$transaction-status-bg: $deep-blue;

$sidebar-width: 240px;
