@import '@ping/assets/scss/theme/variables';

@media only screen and (max-width: 1434px) {
  .settings-profile .custom-file {
    width: 25%;
  }
}
@media only screen and (max-width: 1300px) {
}
@media only screen and (max-width: 1200px) {
  .markets-capital-item {
    height: 289px;
  }
}
@media only screen and (max-width: 991px) {
  .settings-nav {
    margin-bottom: 15px;
  }
  .settings-profile .custom-file {
    width: 35%;
  }
}

@media only screen and (max-width: 768px) {
  .settings-profile .custom-file {
    width: 45%;
  }
  header button.navbar-toggler i {
    font-size: 30px;
    color: $gray;
  }
  header button.navbar-toggler:focus {
    outline: none;
  }
  .no-fluid .market-pairs {
    margin-bottom: 15px;
  }
  .no-fluid .order-book {
    margin-top: 15px;
  }
  .circle-pulse {
    left: 25px;
    right: inherit;
    top: 48px;
  }
  .header-img-icon {
    margin-top: 10px;
  }
  .header-custom-icon button {
    display: block;
  }
  .wallet .nav {
    margin-bottom: 30px;
  }
  .wallet-address img {
    margin-top: 40px;
  }
  #dark header button.navbar-toggler i {
    color: $loblolly;
  }
  .market-order-item {
    padding: 5px 5px;
    overflow: hidden;
  }
  .market-order-item li {
    font-size: 12px;
  }
  .markets-capital-item {
    height: 304px;
  }
}
@media only screen and (max-width: 576px) {
  .markets-capital-item {
    height: 91.4%;
  }
}
