.external-wallets {
}

body.dark {
  .external-wallets {
    .verifications {
      color: #fff;
    }
  }
}
