$breakpoints: (
  'xs': 0,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  'xxl': 1400px,
);

@mixin media-breakpoint-down($name) {
  $max: map-get($breakpoints, $name);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

@mixin media-breakpoint-up($name) {
  $max: map-get($breakpoints, $name);
  @if $max {
    @media (min-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}
