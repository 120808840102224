@import '@ping/assets/scss/breakpoint';
@import '@ping/assets/scss/theme/variables';

// TODO: make the skeleton available for other places

.skeleton {
  pointer-events: none;
  position: relative;
  color: $mirage-light !important;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    overflow: hidden;
    background: linear-gradient(
        126deg,
        rgba(255, 255, 255, 0) 40%,
        rgba(146, 138, 138, 0.2) 50%,
        rgba(255, 255, 255, 0) 60%
      )
      $dropdown-bg;

    background-size: 200% 100%;
    background-position-x: 180%;
    animation: 1s shine ease-in-out infinite;
  }
}

.deposit {
  $deposit: &;

  display: flex;
  flex-direction: column;
  gap: 2rem;
  transition: all 0.2s;

  &[data-is-asset-selected='false'] > section {
    visibility: hidden;
    pointer-events: none;
  }
  &[data-deposit-address-loading='true'] > section {
    visibility: visible;
  }

  &__asset {
    display: flex;
    flex-direction: column;
    gap: 0.725rem;
  }

  &__coin-select-label {
    display: block;
    color: $cadet-blue !important;
    margin-bottom: 0.5rem;
  }

  &__asset-balance {
    color: $gray !important;
    text-align: right;
    margin-left: auto;

    #{$deposit}[data-is-asset-selected='false'] & {
      visibility: hidden;
    }
    #{$deposit}[data-deposit-address-loading='true'] & {
      visibility: visible;
      @extend .skeleton;
    }
  }

  &__address-section {
    $space: 1.5rem;

    border-block: 1px solid $border-color;
    padding-block: $space;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: $space;
  }

  &__qr-code-wrapper {
    display: none;

    @include media-breakpoint-up('lg') {
      $size: 136px;

      min-width: $size;
      min-height: $size;
      display: block;
    }

    #{$deposit}[data-deposit-address-loading='true'] & {
      @extend .skeleton;
    }
  }

  &__qr-code {
    #{$deposit}[data-deposit-address-loading='true'] & {
      display: none;
    }
  }

  &__address-details {
    flex-grow: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    flex-direction: column;
    gap: 1rem;
  }

  &__address-title {
    color: $cadet-blue;

    #{$deposit}[data-deposit-address-loading='true'] & {
      @extend .skeleton;
    }
  }

  &__address-copy {
    margin-left: auto;

    #{$deposit}[data-deposit-address-loading='true'] & {
      @extend .skeleton;
    }
  }

  &__address-text {
    grid-column: 1 / -1;
    line-break: anywhere;

    #{$deposit}[data-deposit-address-loading='true'] & {
      @extend .skeleton;
    }
  }

  &__info-section {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
  }

  &__info-text {
    &:nth-of-type(odd) {
      color: $cadet-blue;
    }
    &:nth-of-type(even) {
      text-align: right;
    }

    #{$deposit}[data-deposit-address-loading='true'] & {
      @extend .skeleton;
    }
  }

  &__footer-text {
    color: $cadet-blue !important;

    #{$deposit}[data-deposit-address-loading='true'] & {
      @extend .skeleton;
    }
  }
}
