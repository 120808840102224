.pingCopyIcon {
  cursor: pointer;
  path {
    transition: all 0.3s ease;
  }
  &:hover {
    path {
      fill: rgba(255, 255, 255, 0.4);
    }
  }
}
