.overlay {
  --overlay-bg-degree: 180deg;
  --overlay-first-bg: rgb(19 23 34 / 0.86);
  --overlay-first-bg-stop: 14.1%;
  --overlay-second-bg: rgba(19 23 34 / 0.7);
  --overlay-second-bg-stop: 100%;
  --overlay-position: absolute;
  --overlay-z-index: 0;

  width: 100%;
  height: 100%;
  position: var(--overlay-position);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: var(--overlay-z-index);
  background: linear-gradient(
    var(--overlay-bg-degree),
    var(--overlay-first-bg) var(--overlay-first-bg-stop),
    var(--overlay-second-bg) var(--overlay-second-bg-stop)
  );
}
