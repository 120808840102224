@import '@ping/assets/scss/theme/variables';

$custom-select-input-height: 48px;
.coin-select-wrapper {
  position: relative;
  width: 100%;
  background: $dropdown-bg;

  .coin-select-header,
  .coin-select-dropdown-trigger {
    box-sizing: border-box;
    height: $custom-select-input-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 12px 12px 16px;
    font-family: Inter, sans-serif;
    font-size: 16px;
    color: $white;
    overflow: hidden;
    .coin-select-value {
      display: flex;
      justify-content: center;
      img {
        height: 18px;
        width: 18px;
        font-size: 16px;
        margin-right: 9px;
      }
    }
  }
  .coin-select-dropdown-trigger {
    padding: 0;
    width: 100%;
  }
}

.coin-select-drop-down-wrapper {
  position: absolute;
  padding: 16px;
  left: 0;
  right: 0;
  background: $dropdown-bg;
  border-top: solid 0.5px $border-color;
  z-index: 99999;

  .coin-select-search {
    padding: 12px 16px;
    background: $border-color;
    display: flex;
    gap: 8px;
    margin-bottom: 16px;
    border-radius: 3px;

    .coin-select-search-icon {
      path {
        fill: $cadet-blue;
      }
    }

    .coin-select-search-input {
      background: $border-color;
      border: none;
      outline: none;
      width: 100%;
      caret-color: $white;
      color: $white;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: $cadet-blue;
      }
    }
  }

  .coin-select-dropdown-item-wrapper {
    max-height: 305px;
    overflow-y: auto;
    .coin-select-dropdown-item {
      border-bottom: 1px solid $border-color;
      padding: 15px 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: $white;
      &:last-child {
        border-bottom: none;
      }
      &:hover,
      &:focus {
        background: #3e445cff;
        outline: none;
      }

      &[data-is-selected-already='true'] {
        display: none;
      }
    }
  }
}
