@import '@ping/assets/scss/theme/variables';

body {
  font-family: 'Inter', sans-serif !important;
  font-size: 14px;
  line-height: 1.5;
  color: $dark-blue;
  background: $white;
  font-weight: 400;
}
