@keyframes shine {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes rotation {
  100% {
    transform: rotate(360deg);
  }
}
