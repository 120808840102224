@import '@ping/assets/scss/theme/variables';

.confirmation-modal {
  :global .popup__content {
    border-top: 4px solid $red;
  }

  &-content {
    margin-top: 24px;
  }

  .confirmation-title {
    font-weight: bold;
    font-size: 1.2rem;
    color: $white;
    text-align: center;
  }

  .confirmation-actions {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    .action-item {
      display: inline-flex;
      margin: 5px;
    }
  }
}
