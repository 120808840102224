@import '@ping/assets/scss/theme/variables';

.market-order {
  &_status {
    &_green {
      color: $green;
      border: none !important;
    }
    &_red {
      color: $torch-red;
      border: none !important;
    }
  }
  .nav-tabs {
    @media (max-width: 768px) {
      justify-content: center;
      padding-bottom: 50px;
    }
    @media (max-width: 420px) {
      padding-bottom: 75px;
    }
  }
  .nav-link {
    padding: 10px 12px;
    user-select: none;
  }
  .no-data-icon {
    margin: 85px !important;
    user-select: none;
  }
  .non-logged-in-user_no-data-icon {
    position: absolute;
    left: 50%;
    top: 55%;
    transform: translate(-50%, -50%);
    margin: 0 !important;
  }
  position: relative;
  min-height: 392px;
  border-radius: 2px;
}

.market-order-balance {
  position: absolute;
  color: $white;
  top: 0.375rem;
  font-size: 1rem;
  right: 1.25rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;

  @media (max-width: 768px) {
    justify-content: center;
    padding-bottom: 50px;
    width: 100%;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  &__title {
    color: $gray;
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  &__value {
    font-size: 20px;
  }

  svg {
    cursor: pointer;
  }
}

.market-order__tooltip-container {
  color: $cadet-blue;
}

.market-order__tooltip-divider {
  background-color: $border-color;
  margin: 10px 0;
}

.market-order__cancel-all-btn {
  &:hover {
    color: white;
    font-weight: 500;
  }
  cursor: pointer;
  text-align: center;
}

.market-order__cancel-icon {
  &:hover svg path {
    fill: $red;
  }
  svg {
    path {
      fill: $cadet-blue;
    }
    transform: scale(0.8);
  }
  cursor: pointer;
}
